import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Campaign from '../routes/campaign';
import PageNotFound from '../routes/page-not-found';
import * as dayjs from 'dayjs';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as dayjs_plugin_utc from 'dayjs/plugin/utc';
import Home from '../routes/home';
  // Support for localized format in dayjs
  dayjs.extend(LocalizedFormat);
  dayjs.extend(dayjs_plugin_utc);
  import '../i18n/i18n';

const App = () => (
	<div id="app">
		<Header />
		<Router>
			<Home path=""  />
			<Campaign path="/:campaignUrl" />
			<PageNotFound path="/page-not-found" />
			<PageNotFound type="404" default />
		</Router>
	</div>
)

export default App;
