import { h } from 'preact';
// import { Link } from 'preact-router/match';
import style from './style.css';
import logo from '../../assets/images/vayam_logo.png';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
const Header = () => (
	<header class={style.header}>
		<a><img className={style.logo} height={40} src={logo} alt="Vayam" /></a>
		{/* <nav>
			<Link activeClassName={style.active} href="/">Home</Link>
			<Link activeClassName={style.active} href="/profile">Me</Link>
			<Link activeClassName={style.active} href="/profile/john">John</Link>
		</nav> */}
		<SelectLanguage />
	</header>
);

export default Header;
