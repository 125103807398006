import styles from './RadioButton.module.css';
import PropTypes from 'prop-types';

function RadioButton({ options, onClick, selectedValue }) {
  return (
    <div className={styles.container}>
      {options.map(({ label, value }, index) => (
        <div className={styles.radio} onClick={() => onClick(value)} key={index}>
          <input name="lang" type="radio" checked={value === selectedValue} />
          <label htmlFor="lang" className={styles.radioLabel}>
            {label}
          </label>
        </div>
      ))}
    </div>
  );
}

RadioButton.defaultProps = {};

RadioButton.propTypes = {
  options: PropTypes.array,
  selectedValue: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default RadioButton;
