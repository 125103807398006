import styles from './SelectLanguage.module.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "preact/hooks";

import langIcon from '../../assets/images/language.svg';
import RadioButton from '../RadioButton/RadioButton';
import i18next from 'i18next';
import classNames from 'classnames';

// Select Language dropdown in event header
const SelectLanguage = (props) => {
  const [dropdownOpen, setToggle] = useState(false);
  const { i18n } = useTranslation();
  const languages = [{ label: 'English', value: 'en' }, { label: 'हिंदी', value: 'hi' }];
  const [selectedValue, setSelectedValue] = useState('en');
  const params = new URLSearchParams(window.location.search);

  console.log(params, props);
  const changeLanguage = language => {
    setSelectedValue(language);
    i18next.changeLanguage(language).then(t => {
      console.log('language changed', t, language);
    });
  };

  const lang = params.get('lang') || 'en';
  useEffect(() => {
    if(lang) {
      i18next.changeLanguage(lang || selectedValue);
    }
  }, [lang]);
  

  return (
    <>
      <div className={classNames(styles.module, { [styles.open]: dropdownOpen })}>
        <button onClick={() => setToggle(!dropdownOpen)} className={styles.selectLangBtn}>
          <img className={styles.langIcon} src={langIcon} />
          <p className={classNames(styles.lang, { [styles.open]: dropdownOpen })}>
            {languages.filter(x => x.value == i18n.language)[0]?.label || 'English'}
          </p>
        </button>
      </div>

      {dropdownOpen && (
        <>
          <div className={styles.overlay} onClick={() => setToggle(!dropdownOpen)} />
          <div className={styles.langPopupModal}>
            <RadioButton
              options={languages}
              selectedValue={selectedValue}
              onClick={val => changeLanguage(val)}
            />
          </div>
        </>
      )}
    </>
  );
};

SelectLanguage.defaultProps = {};

SelectLanguage.propTypes = {};

export default SelectLanguage;
